import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.fontFamilies.primary} !important;
  }

  /* width */
  ::-webkit-scrollbar {
    background: transparent;
    width: 4px;
    height: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.azure};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #005dcc;
  }

  .ant-input,
  .ant-select-selection {
    border-radius: 2px;
  }

  .select-dropdown {
    .option-row {
      display: flex;
    }

    .option-left {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 75%;
    }

    .option-right {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: auto;
    }
  }
  input[type="number"] {
    padding-right: 1px;
  }

  .muted {
    color: ${props => props.theme.alphaColors.auroMetalSaurus()} !important;
    font-family: ${props => props.theme.fontFamilies.header};
  }

  .title, .header-font {
    color: ${props => props.theme.alphaColors.auroMetalSaurus()};
    font-family: ${props => props.theme.fontFamilies.header};
  }

  .inline-block {
    display: inline-block;
  }

  .h-100{
    height:100%;
  }

  .w-100 {
    width: 100%;
  }

  .w-50 {
    width: 50%
  }

  .d-flex {
    display: flex!important;
  }

  .flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
  }

  .flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important;
  }

  .flex-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
  }

  .flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
  }

  .center-x-y{
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .center-y {
    display:flex;
    align-items: center;
  }

  .justify-content-center {
    justify-content: center!important;
  }

  .justify-content-end {
    justify-content: flex-end!important;
  }

  .justify-content-between {
    justify-content: space-between!important;
  }

  .align-items-center {
    align-items: center!important;
  }

  .mt-4 {
    margin-top: 1rem !important;
  }

  .mb-4 {
    margin-bottom: 1rem !important;
  }

  .mt-10{
    margin-top: 10px;
  }

  .mr-10{
    margin-right: 10px;
  }

  .mb-10{
    margin-bottom: 10px;
  }

  .ml-10{
    margin-left: 10px;
  }

  th {
    text-align: inherit;
  }

  .mt-5{
    margin-top: 5px;
  }

  .ml-5{
    margin-left: 5px;
  }

  .my-10{
    margin: 10px 0;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .pointer {
    cursor: pointer;
  }

  .disabled-text-table {
    color: ${props => props.theme.colors.lightMuted} !important;
  }

  .text-squant{
    color: ${props => props.theme.colors.doveGray};
  }

  .color-cherry-red{
    color: ${props => props.theme.colors.alizarinCrimson} !important;
  }

  .text-alert{
    color: ${props => props.theme.colors.carminePink};
  }

  .text-grey{
    color: ${props => props.theme.alphaColors.silver("0.78")};
  }

  .color-basalt-grey{
    color: ${props => props.theme.colors.dustGray};
  }

  .sub-heading-text{
    font-size: ${props => props.theme.fontSizes.subHeading};
  }

  .font-small{
    font-size: ${props => props.theme.fontSizes.small};
  }

  .font-smaller{
    font-size: ${props => props.theme.fontSizes.smaller};
  }

  .small {
    font-size: ${props => props.theme.fontSizes.small85};
    font-weight: 400;
  }

  .small-9 {
    font-size: ${props => props.theme.fontSizes.small9} !important;
    font-weight: 400 !important;
  }

  .text-primary {
    color: ${props => props.theme.colors.azure} !important;
  }

  .h5 {
    font-size: ${props => props.theme.fontSizes.subHeading};
  }

  h6,.h6 {
    font-size: ${props => props.theme.fontSizes.subHeading};
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }

  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${props => props.theme.colors.white};
    background-clip: border-box;
    border: 1px solid ${props => props.theme.alphaColors.black("0.125")};
    border-radius: 0.25rem;
  }

  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .mr-auto {
    margin-right: auto !important;
  }

  .font-weight-medium {
    font-weight: 550 !important;
  }

  .font-weight-bold {
    font-weight: 700 !important;
  }

  .separator {
    height: 1px;
    background: ${props => props.theme.colors.alto};
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }


  @-webkit-keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
`;

export default GlobalStyle;
